import React from 'react';
import BasinSingle from './BasinSingle';
import basinContent from '../basin-content';
import './Basins.scss';

class Basins extends React.Component {
	render() {
		const basinTotal = Object.keys(basinContent).length;
		const basinNextTitle = Object.keys(basinContent).map(
			key => basinContent[key].title,
		);

		const basinNextLink = Object.keys(basinContent).map(
			key => basinContent[key].section,
		);

		return (
			<>
				<div id="buffer" className="Container--basin-opening section js--basin">
					<div className="Container">
						<p className="Title Title--1">Coastal Basins</p>
						<div className="Container Container--inner">
							<div className="Columns">
								<div className="Column--single-left">
									<p className="Title Title--6">
										A basin is an area that is hydrologically connected, such as
										a river basin, lake basin or drainage basin. Assessing each
										basin’s impact and sustainability separately allows for the
										integration of multiple restoration measures within the
										context of a specific portion of the coast.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{Object.keys(basinContent).map((key, index) => (
					<BasinSingle
						key={key}
						basinTotal={basinTotal}
						basinCount={1 + index}
						basinNextTitle={basinNextTitle[index + 1]}
						basinNextLink={basinNextLink[index + 1]}
						content={basinContent[key]}
						basinID={key}
					/>
				))}
			</>
		);
	}
}

export default Basins;

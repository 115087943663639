import React from 'react';
import jQuery from 'jquery';

import './TextScroller.scss';

class TextScroller extends React.Component {
	componentDidMount() {
		if (window) {
			window.jQuery = jQuery;
		}

		jQuery(function() {
			const $textScroller = jQuery('.TextScroller__items');

			if (0 < $textScroller.length) {
				const $textScrollerItems = $textScroller.children();
				let counter = 1;

				$textScrollerItems.eq(0).addClass('is-active is-visible'); // Start with first item active

				setInterval(function() {
					let thisItem = $textScrollerItems.eq(counter);

					if ($textScrollerItems.hasClass('is-active is-visible')) {
						$textScrollerItems.removeClass('is-visible');
						setTimeout(() => {
							$textScrollerItems.removeClass('is-active');
							thisItem.addClass('is-active');
						}, 500);
					}

					setTimeout(() => {
						thisItem.addClass('is-visible');
					}, 1000);

					counter++;

					if (counter === $textScrollerItems.length) {
						counter = 0; // Reset counter after last slide
					}
				}, 5000);
			}
		});
	}

	render() {
		return (
			<div className="TextScroller__wrap">
				<p className="Title Title--0">Envisioning</p>
				<div className="TextScroller">
					<ul className="TextScroller__items">
						<li key="1" className="TextScroller__item TextScroller__item--1">
							<span className="Title Title--0 TextColor--3">
								Our Future
								<br />
								Coast
							</span>
						</li>
						<li key="2" className="TextScroller__item TextScroller__item--2">
							<span className="Title Title--0 TextColor--3">
								A Sustainable
								<br />
								Coast
							</span>
						</li>
						<li key="3" className="TextScroller__item TextScroller__item--3">
							<span className="Title Title--0 TextColor--3">
								A Healthier
								<br />
								Coast
							</span>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default TextScroller;

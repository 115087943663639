import React from 'react';
import Card from '../components/Card';
import './Cards.scss';

class Cards extends React.Component {
	render() {
		const { className, content, name } = this.props;

		return (
			<div className={`Cards Cards--${name} ${className}`}>
				{Object.keys(content).map((card, index) => {
					return (
						<Card
							className={content[card].classes}
							content={content[card].content}
							key={card}
						/>
					);
				})}
			</div>
		);
	}
}

export default Cards;

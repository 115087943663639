import React from 'react';
import Scroll from 'react-scroll-to-element';
import basinContent from '../basin-content';

import './BasinMap.scss';

class BasinMap extends React.Component {
	render() {
		const basinLinks = Object.keys(basinContent);

		return (
			<figure className="Basin--map-svg">
				<svg viewBox="0 0 1579 880.82">
					<Scroll key={basinLinks[0]} type="id" element={basinLinks[0]}>
						<g id="barataria" className="Map-link">
							<circle
								className="Center"
								cx="1051.9"
								cy="501.67"
								r="13.87"
							/>
							<circle
								className="Ring Ring-3"
								cx="1051.9"
								cy="501.67"
								r="23.7"
							/>
							<circle
								className="Ring Ring-2"
								cx="1051.9"
								cy="501.67"
								r="20.23"
							/>
							<circle
								className="Ring Ring-1"
								cx="1051.9"
								cy="501.67"
								r="16.76"
							/>

							<g className="Map-label">
								<rect
									className="cls-2"
									x="1076.17"
									y="477.4"
									width="178.01"
									height="38.15"
								/>
								<rect
									className="cls-3"
									x="1076.75"
									y="477.98"
									width="176.86"
									height="36.99"
								/>
								<g
									className="cls-4"
								>
									<text className="cls-5" transform="translate(1088.89 501.67)">
										BARATARIA BASIN
									</text>
								</g>
							</g>
						</g>
					</Scroll>
					<Scroll key={basinLinks[1]} type="id" element={basinLinks[1]}>
						<g id="pontchartrain" className="Map-link">
							<circle
								className="Center"
								cx="1100.45"
								cy="313.26"
								r="13.87"
							/>
							<circle
								className="Ring Ring-3"
								cx="1100.45"
								cy="313.26"
								r="23.7"
							/>
							<circle
								className="Ring Ring-2"
								cx="1100.45"
								cy="313.26"
								r="20.23"
							/>
							<circle
								className="Ring Ring-1"
								cx="1100.45"
								cy="313.26"
								r="16.76"
							/>
						</g>
					</Scroll>
					<Scroll key={basinLinks[2]} type="id" element={basinLinks[2]}>
						<g id="birdsfoot" className="Map-link">
							<circle
								className="Center"
								cx="1421.79"
								cy="716.68"
								r="13.87"
							/>
							<circle
								className="Ring Ring-2"
								cx="1421.79"
								cy="716.68"
								r="20.23"
							/>
							<circle
								className="Ring Ring-3"
								cx="1421.79"
								cy="716.68"
								r="23.7"
							/>
							<circle
								className="Ring Ring-1"
								cx="1421.79"
								cy="716.68"
								r="16.76"
							/>
						</g>
					</Scroll>

					<Scroll key={basinLinks[3]} type="id" element={basinLinks[3]}>
						<g id="terrebonne" className="Map-link">
							<circle
								className="Center"
								cx="892.38"
								cy="610.33"
								r="13.87"
							/>
							<circle
								className="Ring Ring-3"
								cx="892.38"
								cy="610.33"
								r="23.7"
							/>
							<circle
								className="Ring Ring-2"
								cx="892.38"
								cy="610.33"
								r="20.23"
							/>
							<circle
								className="Ring Ring-1"
								cx="892.38"
								cy="610.33"
								r="16.76"
							/>
						</g>
					</Scroll>

					<Scroll key={basinLinks[4]} type="id" element={basinLinks[4]}>
						<g id="atchafalaya" className="Map=link">
							<circle
								className="Center"
								cx="777.94"
								cy="485.49"
								r="13.87"
							/>
							<circle
								className="Ring Ring-3"
								cx="777.94"
								cy="485.49"
								r="23.7"
							/>
							<circle
								className="Ring Ring-2"
								cx="777.94"
								cy="485.49"
								r="20.23"
							/>
							<circle
								className="Ring Ring-1"
								cx="777.94"
								cy="485.49"
								r="16.76"
							/>
						</g>
					</Scroll>
					<Scroll key={basinLinks[5]} type="id" element={basinLinks[5]}>
						<g id="chenier" className="Map-link">
							<circle
								className="Center"
								cx="147.96"
								cy="442.72"
								r="13.87"
							/>
							<circle
								className="Ring Ring-3"
								cx="147.96"
								cy="442.72"
								r="23.7"
							/>
							<circle
								className="Ring Ring-2"
								cx="147.96"
								cy="442.72"
								r="20.23"
							/>
							<circle
								className="Ring Ring-1"
								cx="147.96"
								cy="442.72"
								r="16.76"
							/>
						</g>
					</Scroll>
				</svg>
			</figure>
		);
	}
}

export default BasinMap;

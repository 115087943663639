import React from 'react';
import { basinWatch } from './helpers';
import basinContent from '../basin-content';

import './BasinNav.scss';

const basins = Object.entries(basinContent).map(basin => [
	basin[0],
	basin[1].title,
	basin[1].section,
]);

const locations = [...basins].map(basin => basin[0]);

class BasinsNav extends React.Component {
	render() {
		return (
			<div className="Basin-nav">
				<ul>
					{basins.map(basin => {
						const [basinID, basinTitle, basinSection] = basin;
						return (
							<li className={basinID} key={basin}>
								<a href={`/#${basinSection}`}>
									<span className="Nav-name">{basinTitle}</span>
									<span className="Nav-marker"></span>
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}

export default BasinsNav;

import React from 'react';

import './Card.scss';

const Card = props => {
	const { className, content } = props;
	return (
		<div
			className={`Card ${className}`}
			dangerouslySetInnerHTML={{ __html: content }}
		></div>
	);
};

export default Card;

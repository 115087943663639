import React from 'react';

import BgImg from './BgImg';

import './PageSection.scss';

class PageSection extends React.Component {
	renderOverlayEffect() {
		if (this.props.overlayEffect) {
			return (
				<div className="PageSection__overlay FadeIn FadeIn--3">
					<span
						className={`OverlayEffect OverlayEffect--${this.props.overlayEffect}`}
					/>
				</div>
			);
		}
	}

	renderOverlayColor() {
		if (this.props.overlayColor) {
			return (
				<div className="PageSection__overlay">
					<span
						className={`Wrap--bg-${this.props.overlayColor}`}
						style={{ opacity: this.props.overlayAlpha }}
					/>
				</div>
			);
		}
	}

	render() {
		const { children, className, id, overlayAlpha, overlayColor } = this.props;
		const { src: bgImg, alt, alpha, credit } = this.props.backgroundImg || {
			src: '',
			alt: '',
			alpha: 1,
			credit: '',
		};

		return (
			<section
				id={id}
				data-showid
				data-section
				className={`PageSection ${className}`}
			>
				{this.renderOverlayEffect()}
				{this.renderOverlayColor()}
				<BgImg
					className="BgImg FadeIn FadeIn--3"
					imgURL={bgImg}
					alt={alt}
					alpha={alpha}
					credit={credit}
				/>
				<div
					className="Wrap"
					style={{
						backgroundColor: `rgba( ${overlayColor}, ${overlayAlpha} )`,
					}}
				>
					<div className="Container">{children}</div>
				</div>
			</section>
		);
	}
}

export default PageSection;

import React from 'react';
import { classToggle } from './helpers';
import './header.scss';

const links = [
	{
		section: '#the-possibilities',
		title: 'The Possibilities',
	},
	{
		section: '#coastal-basins',
		title: 'Coastal Basins',
	},
	{
		section: '#what-you-can-do',
		title: 'What you can do',
	},
];
const sections = [...links].map(link => [link.section, link.title]);
const locations = [...sections].map(section => section[0]);

class Header extends React.Component {
	componentDidMount() {
		const mobileMenu = document.querySelector('.Hamburger');
		const navUl = document.querySelector('.Nav--list');
		const menuOpen = 'Nav-open';
		classToggle(mobileMenu, navUl, menuOpen);
	}
	render() {
		return (
			<nav className="Wrap--main-nav">
				<div className="Nav--main">
					<a href="#intro">
						<h1 className="SiteSlogan">Envisioning our future coast</h1>
					</a>
					<i className="fas fa-bars Hamburger"></i>
					<ul className="Nav--list">
						{sections.map(section => {
							const [a, b] = section;
							return (
								<li key={a}>
									<a href={a}>
										<span>{b}</span>
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</nav>
		);
	}
}

export default Header;

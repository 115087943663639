import React from 'react';
import PropTypes from 'prop-types';

import './BgImg.scss';

class BgImg extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		imgURL: PropTypes.string.isRequired,
		alpha: PropTypes.number,
		alt: PropTypes.string.isRequired,
		credit: PropTypes.string,
	};

	render() {
		const { className, imgURL, alpha = 1, alt, credit = '' } = this.props;

		return (
			<>
				<div className={className}>
					<img src={imgURL} alt={alt} style={{ opacity: alpha }} />
				</div>

				{credit.length > 0 ? (
					<figcaption className="BG-caption Title Title--8">
						{credit}
					</figcaption>
				) : (
					''
				)}
			</>
		);
	}
}

export default BgImg;

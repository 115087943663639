import React from 'react';

class TextFadeByChar extends React.Component {
	render() {
		const characters = [...this.props.children];
		const { start = 100, stop = 0 } = this.props;

		return characters.map((character, i) => {
			if (' ' === character) {
				return character;
			}

			const max = start;
			const min = stop / 100;

			const opacityLevel =
				// min + (max * (characters.length - i)) / (characters.length + 1) / max;
				min + (max * (characters.length - i)) / (characters.length + 1) / 100;

			return (
				<span key={i} style={{ opacity: opacityLevel }}>
					{character}
				</span>
			);
		});
	}
}

export default TextFadeByChar;

import React from 'react';
import PropTypes from 'prop-types';
import SCAPE from '../images/SCAPE-full.jpg';
import './BasinSingle.scss';

class SingleBasin extends React.Component {
	static propTypes = {
		basinID: PropTypes.string.isRequired,
		basinTotal: PropTypes.number.isRequired,
		basinCount: PropTypes.number.isRequired,
		basinNextTitle: PropTypes.string,
		basinNextLink: PropTypes.string,
		content: PropTypes.shape({
			title: PropTypes.string.isRequired,
			content: PropTypes.string.isRequired,
			tableTitle: PropTypes.string.isRequired,
			tableData: PropTypes.shape({
				datatype1: PropTypes.shape({
					fact: PropTypes.string.isRequired,
				}),
				datatype2: PropTypes.shape({
					fact: PropTypes.string.isRequired,
				}),
			}),
		}).isRequired,
	};

	render() {
		const {
			basinTotal,
			basinCount,
			basinNextTitle,
			basinNextLink,
			basinID,
		} = this.props;
		const { title, content, image, tableTitle, tableData } = this.props.content;

		return (
			<div
				id={basinID}
				data-showid
				data-section
				data-count={basinID}
				className={`Basin--single Anim--fade-in section js--basin`}
			>
				<section className="Basins--wrap">
					<div className="Basins--inner">
						<div className="Basins--Title-wrap">
							<h2 className="Title Title--1">{title}</h2>
						</div>

						{image ? (
							<img data-needs-review src={image} alt={title} />
						) : (
							<div className="Basin-img-spacer" />
						)}
						<div className="Basin-bottom">
							<div className="SlideIn--right Basin-data">
								<p dangerouslySetInnerHTML={{ __html: content }} />

								{title === 'Barataria Basin' ? (
									<a
										className="Button Basin-button"
										target="_blank"
										rel="noopener noreferrer"
										href={SCAPE}
									>
										<span>View Barataria Basin Projects</span>
									</a>
								) : (
									''
								)}
							</div>

							<div className="Basins--table Anim--fade-left">
								<h4>{tableTitle}</h4>
								<ul>
									{Object.keys(tableData).map((key, index) => (
										<li key={index}>
											<p
												dangerouslySetInnerHTML={{
													__html: tableData[key].fact,
												}}
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="next-basin">
							<a href={`#${basinNextLink}`}>
								<i className="fal fa-arrow-down"></i> Next {basinCount}/
								{basinTotal}: {basinNextTitle}
							</a>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default SingleBasin;

import React from 'react';
import PropTypes from 'prop-types';

import './BgVideo.scss';

class BgVideo extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		videoURL: PropTypes.string.isRequired,
	};

	render() {
		const { className, videoURL } = this.props;

		return (
			<div className={className}>
				<video width="1280" height="720" data-keepplaying autoPlay muted loop>
					<source src={videoURL} type="video/mp4" />
				</video>
			</div>
		);
	}
}

export default BgVideo;

import React from 'react';
import jQuery from 'jquery';

// Import Favicons
const context = require.context('../images/favicon', true, /\.(jpg)$/);
const importedFavicons = {};

// Build object from imported favicons
context.keys().forEach(item => {
	importedFavicons[item] = context(item);
});

class Favicon extends React.Component {
	componentDidMount() {
		if (window) {
			window.jQuery = jQuery;
		}
		const $ = jQuery;

		$(function() {
			const favicons = Object.values(importedFavicons);
			let counter = 0; // To keep track of the current image

			setInterval(function() {
				$('link[rel="icon"]').remove();
				$('link[rel="shortcut icon"]').remove();
				$('head').append(
					`<link rel="icon" href="${favicons[counter]}" type="image/jpg">`,
				);

				// If last image then goto first image
				if (counter === favicons.length - 1) counter = 0;
				// Else go to next image
				else counter++;
			}, 80);
		});
	}
	render() {
		return null;
	}
}

export default Favicon;

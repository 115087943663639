const solutionCards = {
	card1: {
		classes: '',
		content: `<p class="Card__title Title Title--5">Sediment Diversions</p><hr /><p class="Title Title--9">Implement controlled diversions of river water to carry sediment that revives collapsing wetlands by capturing river born soils, and build new land where the old has disappeared.</p>`,
	},
	card2: {
		classes: '',
		content: `<p class="Card__title Title Title--5">Barrier Islands and Headlands</p><hr /><p class="Title Title--9">Manage barrier islands and headlands not as fixed places, but as part of an evolving and responsive delta system that creates natural balance.</p>`,
	},
	card3: {
		classes: '',
		content: `<p class="Card__title Title Title--5">New Navigation Options</p><hr /><p class="Title Title--9">Find a means to allow ships to navigate into and out of the ports without allowing saltwater to intrude and freshwater and soil to escape.</p>`,
	},
	card4: {
		classes: '',
		content: `<p class="Card__title Title Title--5">Natural Barriers</p><hr /><p class="Title Title--9">Buffer communities from hurricane surge and rising seas by rebuilding natural barriers.</p>`,
	},
	card5: {
		classes: '',
		content: `<p class="Card__title Title Title--5">Healthy Estuaries</p><hr /><p class="Title Title--9">Maintain healthy estuaries by restoring the natural balance between fresh and saltwater.</p>`,
	},
	card6: {
		classes: '',
		content: `<p class="Card__title Title Title--5">Limit Wetland Loss</p><hr /><p class="Title Title--9">Prevent or reduce erosion of wetland shorelines caused by human activities, relying wherever possible on natural solutions like oyster reefs.</p>`,
	},
};

export default solutionCards;

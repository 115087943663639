import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { Link } from 'gatsby-plugin-modal-routing';

// Components
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/Footer';
import BgImg from '../components/BgImg';
import BgVideo from '../components/BgVideo';
import PageSection from '../components/PageSection';
import TextScroller from '../components/TextScroller';
import Basins from '../components/Basins';
import SEO from '../components/seo';
import TextFadeByChar from '../components/TextFadeByChar';
import Favicon from '../components/Favicon';
import Cards from '../components/Cards';
import BasinNav from '../components/BasinNav';
import BasinMap from '../components/BasinMap';

// Helpers
import { listenInView, addBasinClass } from '../components/helpers';

// Styles
import '../components/Wrap.scss';

// Media
import IntroVideo from '../videos/background-video-3.2.mp4';
import PreviewVideo from '../videos/video-preview-3.0.mp4';
import coastalBirds from '../images/wff-sec5-01.jpg';
import sandyNguyen from '../images/wff-sec5-02.jpg';
import fishingBoat from '../images/wff-sec5-03.jpg';
import happyJohnson from '../images/wff-sec5-04.jpg';
import deadtree from '../images/deadtree.jpg';
import birdOverBasin from '../images/bird-over-basin.jpg';
import birdsOverRiver from '../images/birds-over-river.jpg';
import manOnBeach from '../images/man-on-beach.jpg';
import basinOverhead from '../images/basin-overhead.jpg';
import fishing from '../images/fishing.jpg';
import coastline from '../images/coastline.jpg';

// Content
import solutionCards from '../solutions-content';

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
	require('../../static/fullpage.scrollOverflowReset.min.js');
};

const IndexPage = (fullpageProps, { errorPath }) => (
	<Layout onScroll={listenInView()}>
		<SEO />
		<Favicon />
		<BasinNav />
		<div data-basin="off" className="Container--basin-map">
			<BasinMap />
		</div>
		{!errorPath ? <Header /> : ''}
		<ReactFullpage
			pluginWrapper={pluginWrapper}
			licenseKey={'6B8C9875-278740A7-B4A6670C-A22EEBC9'}
			anchors={[
				'intro',
				'envisioning-our-coast',
				'the-possibilities',
				'disappearing-land',
				'future-coast-is-possible',
				'opportunities',
				'how-do-we-get-there',
				'where-do-we-focus',
				'coastal-basins',
				'barataria-basin',
				'pontchartrain-basin',
				'birdsfoot-delta',
				'terrebonne-basin',
				'greater-atchafalaya-basin',
				'chenier-plain',
				'make-the-future-bright',
				'what-you-can-do',
			]}
			scrollOverflow={true}
			touchSensitivity={40}
			onLeave={(origin, destination, direction) => {
				const current = destination.item;
				const html = document.querySelector('html');

				function containsClass(el, theClass) {
					return el.classList.contains(theClass) ? true : false;
				}

				if (containsClass(current, 'js--basin')) {
					addBasinClass(current.id); /* Updates Basins Map */

					if (false === containsClass(html, 'Basins--is-active')) {
						html.classList.add('Basins--is-active');
					}
				} else {
					if (true === containsClass(html, 'Basins--is-active')) {
						html.classList.remove('Basins--is-active');
					}
				}

				// gtag event tracking
				if (window && window.gtag) {
					window.gtag('event', 'view', {
						event_category: 'Page Sections',
						event_label: `${current.dataset.anchor}`,
					});
				}
			}}
			{...fullpageProps}
			render={({ state, fullpageApi }) => {
				return (
					<div id="fullpage-wrapper">
						<section id="sec-1" data-showid data-section className="section">
							<BgVideo
								className="BgVideo FadeIn FadeIn--3"
								videoURL={IntroVideo}
							/>
							<div className="Container">
								<TextScroller />
								<div className="Wrap--info">
									<div className="alignright">
										<a
											href="#envisioning-our-coast"
											className="CircleButton CircleButton--scroll-more SlideIn--top SlideIn--7"
										>
											<i className="fal fa-arrow-down fa-lg"></i>
										</a>
										<br />
										<p
											id="experience-prompt"
											className="Title Title--8 SlideIn--left SlideIn--3"
										>
											For a richer experience view on desktop &emsp;
											<i className="fal fa-tv"></i>
										</p>
									</div>
								</div>
							</div>
						</section>
						<section
							id="sec-1-b"
							data-showid
							data-section
							className="section fp-noscroll"
						>
							<BgImg
								className="BgImg FadeIn FadeIn--3"
								imgURL={manOnBeach}
								alt="Man standing on a beach"
								alpha={0.7}
								credit={'Photo credit: Amanda Toups'}
							/>
							<div className="Wrap">
								<div className="Container">
									<h2 className="Title Title--1">
										Envisioning
										<br /> Our Coast
									</h2>
									<div className="Container Container--inner SlideIn--left SlideIn--2">
										<div className="Columns">
											<div className="Column Column--single-left">
												<p className="Title Title--6">
													The fate of our Louisiana coast is in our hands.{' '}
													<strong className="InlineTextHighlight">
														A revived coast is within reach.
													</strong>{' '}
													Though the wetland footprint will be smaller,
													reconnecting the Mississippi River to our coastal
													wetlands means it could be far richer than anything
													we’ve seen in our lifetimes. To get there we must act
													to carry out Louisiana’s Coastal Master Plan, the plan
													of today, and the projects that our collective vision
													add to future versions.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="sec-2" data-showid data-section className="section">
							<BgVideo
								className="BgVideo BgVideo--alt FadeIn FadeIn--3"
								videoURL={PreviewVideo}
							/>
							<div className="Wrap">
								<div className="Container">
									<h2 className="SlideIn--right SlideIn--1">
										<span className="Title Title--3">
											What does the envisioned Future Coast
										</span>
										<br />
										<span className="Title Title--1">Look Like?</span>
									</h2>
									<div className="Container Container--inner">
										<div className="Columns Columns--reverse">
											<div className="Column Column--left Column--content-center">
												<Link
													className="CircleButton CircleButton--video SlideIn--bottom SlideIn--3"
													to={`/video/envisioning/`}
													state={{
														modal: true,
													}}
													asModal
												>
													<i className="fal fa-video fa-lg fa-2x"></i>
													<span>Play Video</span>
												</Link>
											</div>
											<div className="Column Column--right SlideIn--left SlideIn--2">
												<p className="Title Title--6">
													Our Future Coast looks a lot like what Old World
													people found when they arrived here 300 years ago and
													first met indigenous communities;{' '}
													<strong className="InlineTextHighlight">
														A coast teeming with bountiful wildlife, birds, fish
														and shellfish — an abundance that has been lost for
														generations.
													</strong>{' '}
													We lost that abundance when the Mississippi River lost
													its connection to our coastal wetlands — but we can
													get it back with well designed restoration projects
													that harness the power of the River to build new land
													the way nature always intended.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<PageSection
							id="sec-3"
							className="section"
							backgroundImg={{
								src: deadtree,
								alt: 'Coastal wetlands disappearing',
								alpha: 0.7,
							}}
							overlayEffect="fog"
							overlayColor="gray-light"
							overlayAlpha={0.2}
						>
							<h2 className="Title Title--1 SlideIn--right SlideIn--1 TextColor--3">
								<TextFadeByChar start={80} stop={10}>
									The most rapidly disappearing land in America
								</TextFadeByChar>
							</h2>
							<div className="Container Container--inner SlideIn--bottom SlideIn--2">
								<div className="Columns">
									<div className="Column Column--single-right">
										<p className="Title Title--6">
											Because of human attempts to control the river and modify
											coastal wetlands, what had been growing is now shrinking.
											Wetlands laced with bayous, bays and battures are sinking
											at the fastest rate in our country, and now the sea is
											rising at an ever-increasing pace.{' '}
											<strong className="InlineTextHighlight">
												The clock is ticking to be sure, but it has not yet run
												out.
											</strong>
										</p>
									</div>
								</div>
							</div>
						</PageSection>
						<PageSection
							id="sec-4"
							className="section fp-noscroll"
							backgroundImg={{
								src: birdOverBasin,
								alt: 'Aerial view of coastal wetlands',
								alpha: 0.8,
							}}
							overlayEffect="clouds"
							overlayColor="green-darker"
							overlayAlpha={0.5}
						>
							<h2 className="Title Title--1 SlideIn--right SlideIn--1">
								The Future Coast
								<br />
								<span className="TextColor--3">Is Possible</span>
							</h2>
							<div className="Container Container--inner SlideIn--left SlideIn--2">
								<div className="Columns">
									<div className="Column Column--single-left">
										<p className="Title Title--6">
											Louisiana is home to America’s youngest land, building in
											the few areas where the Mississippi and Atchafalaya Rivers
											are allowed to run free. Forces that we can’t control
											guarantee that much of our coast will disappear in coming
											decades. But we have a window of opportunity right now to
											get ahead of sea level rise.{' '}
											<strong className="InlineTextHighlight">
												We can grow new land that can keep up with rising seas
												by reconnecting the River to our coastal wetlands.
											</strong>{' '}
										</p>
									</div>
								</div>
							</div>
						</PageSection>
						<PageSection
							id="sec-5"
							className="PageSection--bgAlt Wrap--bg-black section"
							backgroundImg={{
								src: birdsOverRiver,
								alt: 'What our future wetlands can look like',
							}}
							overlayColor="black"
							overlayAlpha={0.5}
						>
							<h2 className="SlideIn--right SlideIn--1">
								<span className="Title Title--1">Envisioning</span>
								<br />
								<span className="Title Title--2">The Opportunities</span>
							</h2>
							<div className="Container Container--inner">
								<div className="Columns SlideIn--right SlideIn--2">
									<div className="Column Column--left">
										<h4 className="Title Title--4">Ecological</h4>
										<p className="Title Title--6">
											Restoring the coast is about more than just saving
											wetlands, it’s about{' '}
											<strong className="InlineTextHighlight">
												restoring the natural balance between land and sea that
												healthy deltas provide.
											</strong>{' '}
											Before we interfered, the Mississippi River was able to
											build new land in one area of the delta while the sea
											reclaimed older delta land nearby. Nature was in balance,
											and the result was an abundance of life: wintering ducks
											and geese in freshwater areas, extensive oyster reefs
											where the fresh and the saltwater met and vast colonies of
											iconic Louisiana animals like the brown pelican in the
											salty bays.
										</p>
									</div>

									<div className="Column Column--right">
										<figure>
											<img src={coastalBirds} alt="Coastal Birds" />
											<figcaption>
												Coastal Birds. Photo credit: Skip Armstrong
											</figcaption>
										</figure>
									</div>
								</div>
								<div className="Columns Columns--reverse SlideIn--left SlideIn--3">
									<div className="Column Column--left">
										<h4 className="Title Title--4">Economic</h4>
										<p className="Title Title--6">
											<strong className="InlineTextHighlight">
												Louisiana’s coastal economy relies upon three things:
											</strong>
										</p>
										<ul>
											<li>
												<strong>Location: </strong> Where the greatest inland
												waterway system in the world — the Mississippi and its
												tributaries — meets the world’s oceans and shipping
												lanes. A gigantic network of ports connect inland North
												America to the rest of the world on the Louisiana coast;
											</li>
											<li>
												<strong>Natural Resources: </strong> whether seafood in
												its estuaries, ducks in its marshes or oil and gas
												buried deep beneath its soils, the delta is incomparably
												rich; and
											</li>
											<li>
												<strong>Tourism: </strong> people from all over the
												world come to experience its resources and the vibrant
												culture that depends upon them.
											</li>
										</ul>
									</div>

									<div className="Column Column--right">
										<figure>
											<img
												src={sandyNguyen}
												alt="Sandy Nguyen examining locally caught seafood"
											/>
											<figcaption>
												Sandy Nguyen. Photo credit: Blake Hendrix
											</figcaption>
										</figure>
									</div>
								</div>
								<div className="Columns Columns SlideIn--left SlideIn--3A">
									<div className="Column Column--left">
										<p className="Title Title--6">
											<strong className="InlineTextHighlight">
												The envisioned Future Coast will rely upon the same
												foundations, but will be different, changed by emerging
												circumstances:
											</strong>
										</p>
										<ul>
											<li>
												Louisiana’s ports rely upon nineteenth century
												techniques to keep navigation channels open. The future
												must bring a navigation system that allows the ships to
												reach our ports, but retains and distributes freshwater
												and sediment from the continent to build and sustain
												wetlands.
											</li>
											<li>
												Though a major supplier of wild caught seafood to the
												nation, competition from overseas aquaculture threatens
												the livelihoods for Louisiana harvesters. If integrated
												into the fabric of restoration, innovation can keep
												Louisiana competitive and provide a bridge to an
												abundant future for traditional harvesters.
											</li>
											<li>
												A coast reconnected to its River will provide
												opportunities for continuing to catch, hunt and enjoy
												the fish and wildlife we know today, though not in the
												same locations. Charged by the River’s energy, the
												future coast will allow expansion of ecotourism, hunting
												and fishing, and will support greater abundance and
												variety.
											</li>
										</ul>
									</div>
									<div className="Column Column--right">
										<figure>
											<img src={fishingBoat} alt="Fishing boat docked" />
											<figcaption>
												Fishing boat. Photo credit: Yuki Kokubo
											</figcaption>
										</figure>
									</div>
								</div>
								<div className="Columns SlideIn--right Columns--reverse SlideIn--4">
									<div className="Column Column--left">
										<h4 className="Title Title--4">Economic Opportunity</h4>
										<p className="Title Title--6">
											Restoration at the scale needed is costly, but{' '}
											<strong className="InlineTextHighlight">
												spending the money now saves money over the long run and
												creates new jobs and economic opportunities.
											</strong>{' '}
											This comes just as the world begins the transition from
											the old fossil fuel based economy to one based on
											renewable energy and sustainable systems.
										</p>
										<ul>
											<li>
												As the most rapidly sinking place in the United States,
												Louisiana’s innovations can be exported to coastal areas
												around the world as they face what we have already
												learned to overcome: rising seas.
											</li>
											<li>
												Other industries will also see the positive effects,
												with new emphasis put on transitioning to renewable
												energies like wind and solar power, and even the
												electrification of maritime transportation and
												machinery.
											</li>
										</ul>
									</div>
									<div className="Column Column--right">
										<figure>
											<img
												src={happyJohnson}
												alt="Happy Johnson sitting on a riverbank"
											/>
											<figcaption>
												Happy Johnson. Photo credit: Blake Hendrix
											</figcaption>
										</figure>
									</div>
								</div>
							</div>
						</PageSection>
						<PageSection
							id="sec-6"
							className="section"
							backgroundImg={{
								src: basinOverhead,
								alt: 'Aerial view of disappearing coastal wetlands',
								credit: 'Photo credit: Blake Hendrix',
							}}
						>
							<h2 className="Title Title--1 SlideIn--right SlideIn--1">
								How do we
								<br />
								<span className="TextColor--3">get there?</span>
							</h2>
							<div className="Container Container--inner SlideIn--left SlideIn--2">
								<Cards content={solutionCards} name="solutions" />
							</div>
						</PageSection>
						<PageSection
							id="sec-7"
							data-showid
							className="section fp-noscroll"
							backgroundImg={{
								src: fishing,
								alt: 'Man in a boat examining wetlands',
								credit:
									'Photo credit: Leslie Von Pless, Environmental Defense Fund',
							}}
						>
							<h2 className="Title Title--1 SlideIn--right SlideIn--1">
								<span className="TextColor--3">Where</span> Do
								<br />
								We Focus?
							</h2>
							<div className="Container Container--inner SlideIn--left SlideIn--2">
								<div className="Columns">
									<div className="Column Column--single-left">
										<p className="Title Title--6">
											Given the variation across Louisiana’s coastal basins,
											including differences in geology, biological diversity and
											human habitation and use,{' '}
											<strong className="InlineTextHighlight">
												taking a basin-by-basin approach proved to be most
												effective
											</strong>
											. Assessing each basin’s impact and sustainability
											separately allows for the integration of multiple
											restoration measures within the context of a specific
											portion of the coastal landscape.
										</p>
									</div>
								</div>
							</div>
						</PageSection>
						<Basins />

						<PageSection
							id="sec-8"
							data-showid
							className="section fp-noscroll"
							backgroundImg={{
								src: coastline,
								alt: 'Aerial view of our coast with wetlands behind it',
								credit: 'Photo credit: Blake Hendrix',
							}}
							overlayColor="black"
							overlayAlpha={0.15}
						>
							<h2 className="Title Title--1 SlideIn--right SlideIn--1">
								Let’s Make The
								<br />
								Future <span className="TextColor--3">Bright</span>
							</h2>
							<div className="Container Container--inner SlideIn--left SlideIn--2">
								<div className="Columns">
									<div className="Column Column--single-left">
										<p className="Title Title--6">
											The state of Louisiana’s coast has put us under the
											nation’s spotlight. What we do with this attention is now
											up to us. It’s time to show the world a new Louisiana. One
											that is innovative and sustainable — one that will be a
											catalyst for change.{' '}
											<strong className="InlineTextHighlight">
												Together we can pave the way for coasts around the world
												and set a new global standard.
											</strong>
										</p>
									</div>
								</div>
							</div>
						</PageSection>
						<Footer className="section" />
					</div>
				);
			}}
		/>
	</Layout>
);

export default IndexPage;

const basinContent = {
	basin1: {
		title: 'Barataria Basin',
		content: `The Barataria Basin has played an essential role in the production of natural resources and shaping the rich history and development of Southeastern Louisiana and New Orleans. This basin's estuary serves as a bountiful host to some of Louisiana's oysters, shrimp and fish, feeding locals, visitors and the nation. Protecting this area is critical, and it starts with implementing sediment diversions and managing sediment retention. Projects are already underway in the Barataria Basin, including storm surge management systems, maintenance of barrier island system and efforts to rebuild marsh. Together, these projects will propel Barataria towards greater prductivity.`,
		tableTitle: 'Facts and figures',
		tableData: {
			datatype1: {
				title: '1,370,000',
				fact:
					'According to the Louisiana CPRA, there are 89 active projects in the Barataria Basin, benefitting around <strong class="InlineTextHighlight">1.37 million acres.</strong>',
			},

			datatype2: {
				title: '430',
				fact:
					'Between 1932-2016, the Barataria Basin lost <strong class="InlineTextHighlight">430 square miles</strong> of land.',
			},
			datatype3: {
				title: '5,700',
				fact: `Between 1974-1990, the Barataria Basin lost an average of nearly <strong class="InlineTextHighlight">5,700 acres</strong> of wetlands per year.`,
			},
		},
		section: 'barataria-basin',
	},

	basin2: {
		title: 'Pontchartrain Basin',
		content: `Pontchartrain Basin is the most populous and expansive coastal basin in Louisiana, playing host to 40% of Louisiana's 4.66 million people, making it the most densely populated basin in the region. The projects targeting Pontchartrain are chiefly concerned with managing storm surge, strategically rebuilding land bridges that separate major water bodies and getting the balance of fresh and saltwater right.`,
		tableTitle: 'Facts and figures',
		tableData: {
			datatype1: {
				title: '48',
				fact:
					'According to the Louisiana CPRA, there are <strong class="InlineTextHighlight">48 active projects</strong> in the Pontchartrain Basin, benefitting around 1.36 million acres.',
			},
		},
		section: 'pontchartrain-basin',
	},
	basin3: {
		title: 'Birdsfoot Delta',
		content: `As home to the navigation channel for seagoing vessels making their way into the Mississippi River, the iconic delta is significant not only to the state but also to our nation's economy. This region is a critical habitat for a large number of wading birds, as well as more than one million waterfowl annually, making it deeply intertwined with our local ecosystem. The effects the outdated navigation system on the Birdsfoot Delta have strained, with soil wasted by dredging and jetties sending it into the deep Gulf. Projects in the delta focus on designing a navigation system that can accommodate natural distribution of water and sediment.`,
		tableTitle: 'Facts and figures',
		tableData: {
			datatype1: {
				title: '117',
				fact:
					'Between 1932-2016, the land surrounding the Birdsfoot Delta has diminished from 262 square miles to <strong class="InlineTextHighlight">117 square miles.</strong>',
			},
			datatype2: {
				title: '2,348',
				fact:
					'The Birdsfoot Delta is where the mighty Mississippi ends its journey of <strong class="InlineTextHighlight">more than 2,000 miles</strong> to meet the Gulf of Mexico.',
			},
			datatype3: {
				title: '10,000+',
				fact:
					'<strong class="InlineTextHighlight">Tens of thousands</strong> of wintering waterfowl, wading birds, secretive marsh birds and shorebirds utilize the Birdsfoot Delta’s rich food resources.',
			},
		},
		section: 'birdsfoot-delta',
	},
	basin4: {
		title: 'Terrebonne Basin',
		content: `Initially named for its "good earth" by early settlers, this basin provided highly fertile soils and a freshwater source to inhabitants before its wetlands were devastated in the 20th century. To this day, the “terrebonne” reputation holds true, but it is under attack. Dubbed "restore or retreat country," this area defines the bayou and its uncommon culture. Suggested projects revolve around rebuilding and maintaining barrier islands, reintroducing freshwater from the Atchafalaya River and rebuilding the sunken natural ridges that once protected interior communities.`,
		tableTitle: 'Facts and figures',
		tableData: {
			datatype1: {
				title: '28,600',
				fact:
					' According to the Louisiana CPRA, there are 48 active projects in the Terrebonne Basin, benefitting around <strong class="InlineTextHighlight">28,600 acres.</strong>',
			},
			datatype2: {
				title: '500',
				fact:
					'The Terrebonne Basin lost <strong class="InlineTextHighlight">500 square miles</strong> of land between 1932 -2016.',
			},
		},
		section: 'terrebonne-basin',
	},
	basin5: {
		title: 'Greater Atchafalaya Basin',
		content: `The once extensive reefs along this basin had reduced the exposure of shorelines to erosive waves, moderated tidal exchange and storm surges, and retained freshwater and sediments within the coastal zone. Currently, the Greater Atchafalaya Basin is the only region along the Louisiana coast that did not experience land loss, with positive gains exceeding 1.5% per year since 1998. Maintaining this growth through sediment diversions while monitoring water salinity to ensure ecological stability will be the focal points within this area.`,
		tableTitle: 'Facts and figures',
		tableData: {
			datatype1: {
				title: '65',
				fact:
					'There are <strong class="InlineTextHighlight">65 species</strong> of reptiles and amphibians that live in the Atchafalaya Basin.',
			},
			datatype2: {
				title: '22 million',
				fact:
					'The Greater Atchafalaya Basin has an estimated average annual commercial harvest of nearly <strong class="InlineTextHighlight">22 million pounds</strong> of crawfish.',
			},
			datatype4: {
				title: '6',
				fact:
					'The Atchafalaya Basin has gained <strong class="InlineTextHighlight">six square miles</strong> between 1932 - 2016 and is the only basin in Louisiana to experience net land gain.',
			},
			datatype5: {
				title: '1.4 million',
				fact:
					'The Greater Atchafalaya Basin is home to <strong class="InlineTextHighlight">1.4 million acres</strong> of bottomland hardwood and bald cypress-water tupelo swamp.',
			},
		},
		section: 'greater-atchafalaya-basin',
	},
	basin6: {
		title: 'Chenier Plain',
		content: `Particularly valuable for its abundance, diversity of life and natural resources, the Chenier Plain also provides vital stopover for migrating songbirds and shorebirds. National and state refuges, along with private lands managed for natural resources, bolster the local economy with hunting, fishing and wildlife observation opportunities. Restoration efforts are focused on limiting saltwater intrusion and managing freshwater distribution to maintain marshland, with additional benefits to adjacent agricultural land.`,
		tableTitle: 'Facts and figures',
		tableData: {
			datatype1: {
				title: '5.8 million',
				fact:
					'The Chenier Plain is the wintering home of more than <strong class="InlineTextHighlight">5.8 million</strong> ducks.',
			},
			datatype2: {
				title: '360',
				fact:
					'More than <strong class="InlineTextHighlight">360 species</strong> of birds can be found in the Chenier Plain including ducks, geese, herons, egrets, ibis, storks, spoonbills, raptors, shorebirds and neotropical migrant songbirds.',
			},
		},
		section: 'chenier-plain',
	},
};

export default basinContent;
